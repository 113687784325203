import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiServices from '../../services/api.services'

//assets
import {
  Image1,
  Image2,
  Image3,
  IconBank,
  IconInsurance,
  IconCar,
  IconReceipt,
  IconBooking,
  IconShipping,
  IconRMCD,
  IconReceiveOrder,
  IconInspection,
  IconPurchase,
  IconJapanCheck,
  IconJPJCheck,
  IconUKCheck,
} from '../../assets/images'

// import Intro from '../../assets/videos/homepage/-2471861716072643027.MOV'
// import JPVid from '../../assets/videos/homepage/JPVid.mp4'
// import UKVid from '../../assets/videos/homepage/UKVid.mp4'
// import Video5 from '../../assets/images/sample_960x540.webm'

import Intro from '../../assets/videos/homepage/IntroductionOriginals.mp4'
// import IntroChinese from '../../assets/videos/homepage/IntroductionChinese.mp4'
import Step1 from '../../assets/videos/homepage/step1.mp4'
import Step2 from '../../assets/videos/homepage/step2.mp4'
import Step3 from '../../assets/videos/homepage/step3.mp4'

import PosterIntro from '../../assets/images/homepage/intro.png'
import PosterIntroChinese from '../../assets/images/homepage/introChinese.png'

import PosterStep1 from '../../assets/images/homepage/step1.png'
import PosterStep2 from '../../assets/images/homepage/step2.png'
import PosterStep3 from '../../assets/images/homepage/step3.png'

const initialState = {
  video_playing: '',
  how_it_work: [
    {
      label: 'Reservation',
      icon: IconBooking,
      desc: 'Visit the D-Concept website and book your vehicle.',
    },
    {
      label: 'Purchase',
      icon: IconPurchase,
      desc: "Once the contract is agreed, we'll secure your vehicle immediately.",
    },
    {
      label: 'Shipping',
      icon: IconShipping,
      desc: "We'll take care of sending your vehicle to Malaysia.",
    },
    {
      label: 'RMCD',
      icon: IconRMCD,
      desc: "Customs: We'll handle all the customs paperwork for your vehicle.",
    },
    {
      label: 'Inspection',
      icon: IconInspection,
      desc: "We'll ensure your vehicle passes all necessary inspections.",
    },
    {
      label: 'Delivery',
      icon: IconReceiveOrder,
      desc: 'When everything is done, your vehicle will be delivered to you.',
    },
  ],
  direct_import_listing: [
    {
      image: Image1,
      path: '/',
      // action: () => {
      //   //window.open('https://jpauc.com/', '_blank')
      //   setOpenJapanModal(true)
      // },
      action: 'openJapanLink',
    },
    {
      image: Image2,
      path: '/',
      action: 'https://www.carsales.com.au',
    },
    {
      image: Image3,
      path: '/',
      action: 'https://www.autotrader.co.uk/?postcode=LS19+6LW&refresh=true',
    },
  ],
  direct_import_action: [
    // {
    //   image: IconVerifyDAgent,
    //   colums: "col-3",
    //   label: 'Verify D-Agent',
    //   value: "agent",
    //   navigate: "/calculator?tab=agent",
    // },
    {
      image: IconBank,
      colums: 'col-3',
      label: 'Loan Calculator',
      value: 'loan',
      navigate: '/calculator?tab=loan',
    },
    {
      image: IconInsurance,
      colums: 'col-3',
      label: 'Insurance Calculator',
      value: 'insurance',
      navigate: '/calculator?tab=insurance',
    },
    {
      image: IconReceipt,
      colums: 'col-3',
      label: 'Road Tax Calculator',
      value: 'roadtax',
      navigate: '/calculator?tab=roadtax',
    },
    // {
    //   image: IconCar,
    //   colums: 'col-3 mt-5',
    //   label: 'Compare Model',
    //   value: 'compare',
    //   navigate: '/calculator?tab=compare',
    //   // action: () => {
    //   //   window.open(window.location.href + 'compare_model', '_blank')
    //   // },
    // },
    {
      image: IconJapanCheck,
      colums: 'col-3 mt-5',
      label: 'Japan Car Check',
      action: 'https://scrut.my/',
    },
    {
      image: IconUKCheck,
      colums: 'col-3 mt-5',
      label: 'UK Car Check',
      action: 'https://www.vehiclecheck.co.uk/',
    },
    {
      image: IconJPJCheck,
      colums: 'col-3 mt-5',
      label: 'JPJ Car Plate',
      action: 'https://www.jpj.my/JPJ_Latest_Number_Plates.htm',
    },
  ],
  car_brand: [],
  last_seen_listing: [],
  what_we_do_listing: [
    {
      label: 'Introduction',
      video: { en: Intro, zh: Intro, my: 'comming soon' },
      poster: { en: PosterIntro, zh: PosterIntroChinese },
    },
    {
      label: 'Process 1',
      video: { en: Step1, zh: 'comming soon', my: 'comming soon' },
      poster: PosterStep1,
    },
    {
      label: 'Process 2',
      video: { en: Step2, zh: 'comming soon', my: 'comming soon' },
      poster: PosterStep2,
    },
    {
      label: 'Process 3',
      video: { en: Step3, zh: 'comming soon', my: 'comming soon' },
      poster: PosterStep3,
    },

    // { label: 'Process 1', video: Step1, poster: PosterStep1 },
    // { label: 'Process 2', video: Step2 , poster: PosterStep2 },
    // { label: 'Process 3', video: Step3 , poster: PosterStep3 },
  ],
  ready_stock: {
    d_stock: [],
    direct_owner: [],
  },
  searchData: {},

  lastView: false,
  lastSearchList: [],
  lastSearchData: [],
  lastViewData: [],
  openJapanModal: false,
  openEventModal: false,
  isDarkMode: true,
}

export const getBrand = createAsyncThunk('getBrand', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getBrand(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getNewBrand = createAsyncThunk('getNewBrand', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getNewBrand(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getHomeSearch = createAsyncThunk('get/stock/search', async (payload) => {
  const response = await apiServices.getHomeSearch(payload)
  return response.data
})

export const postLastView = createAsyncThunk('post/last_view', async (payload) => {
  const response = await apiServices.postLastView(payload)
  return response.data
})

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setVideoPlaying: (state, action) => {
      state.video_playing = action.payload
    },

    setLastView: (state, action) => {
      state.lastView = action.payload
    },
    setOpenJapanModal: (state, action) => {
      state.openJapanModal = action.payload
    },
    setOpenEventModal: (state, action) => {
      state.openEventModal = action.payload
    },
    setIsDarkMode: (state, action) => {
      state.isDarkMode = action.payload
    },
  },
  extraReducers: {
    [getBrand.pending]: (state, action) => {},
    [getBrand.fulfilled]: (state, action) => {
      state.last_seen_listing = action.payload.data
    },
    [getNewBrand.pending]: (state, action) => {},
    [getNewBrand.fulfilled]: (state, action) => {
      state.car_brand = action.payload.data
    },
    [getHomeSearch.pending]: (state, action) => {
      state.loading = true
    },
    [getHomeSearch.fulfilled]: (state, action) => {
      state.loading = false
      state.searchData = action.payload
    },

    [postLastView.pending]: (state, action) => {
      state.loading = true
    },
    [postLastView.fulfilled]: (state, action) => {
      state.loading = false
      const allLastData = action.payload

      state.lastViewData = allLastData.data_last_view
      state.lastSearchData = allLastData.data_last_search
      state.lastSearchList = allLastData.available_last_search
    },
  },
})

export const { setVideoPlaying, setLastView, setOpenJapanModal, setOpenEventModal, setIsDarkMode } =
  homeSlice.actions
const { reducer } = homeSlice
export default reducer
