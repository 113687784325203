import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiServices from '../../services/api.services'

const initialState = {
  section: 'loan',
  loanResult: {},
  roadtaxResult: {},
  insuranceResult: {},
  roadtaxParam: {
    region: {},
    car_type: {},
  },
  insuranceParam: {
    coverage_type: {},
    location: {},
    engine_capacity: {},
  },
  loading: false,
  roadtaxData: {
    engine_cc: '',
    region: '',
    car_type: '',
  },
  loanData: {
    total_amount: '',
    down_payment: '',
    interest_rate: '',
    loan_period: '',
  },
  insuranceData: {
    market_price: '',
    coverage_type: '',
    location: '',
    engine_capacity: '',
    ncd: '',
  },
  agentData: [],
  agentVideoData: [],
}

export const postLoan = createAsyncThunk('loan', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.postLoan(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const postRoadtax = createAsyncThunk('roadtax', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.postRoadtax(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const getRoadtax = createAsyncThunk('getRoadtax', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getRoadtax(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const postInsurance = createAsyncThunk('insurance', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.postInsurance(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const getInsurance = createAsyncThunk('getInsurance', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getInsurance(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
export const getDAgent = createAsyncThunk('getDAgent', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getDAgent(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})
const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setSection: (state, action) => {
      state.section = action.payload
    },
    clearAgentData: (state, action) => {
      state.agentData = action.payload
    },
  },
  extraReducers: {
    [postLoan.pending]: (state, action) => {},
    [postLoan.fulfilled]: (state, action) => {
      state.loanResult = action.payload.data
    },
    [postRoadtax.pending]: (state, action) => {},
    [postRoadtax.fulfilled]: (state, action) => {
      state.roadtaxResult = action.payload.data
    },
    [getRoadtax.pending]: (state, action) => {},
    [getRoadtax.fulfilled]: (state, action) => {
      state.roadtaxParam = action.payload.data
    },
    [postInsurance.pending]: (state, action) => {},
    [postInsurance.fulfilled]: (state, action) => {
      state.insuranceResult = action.payload.data
    },
    [getInsurance.pending]: (state, action) => {},
    [getInsurance.fulfilled]: (state, action) => {
      state.insuranceParam = action.payload.data
    },
    [getDAgent.pending]: (state, action) => {
      state.agent_loading = true;
    },
    [getDAgent.fulfilled]: (state, action) => {
      state.agent_loading = false;
      state.agentData = action.payload.data.aaData
      state.agentVideoData = action.payload.data.videoData
    },
  },
})

export const { setSection, clearAgentData } = calculatorSlice.actions

const { reducer } = calculatorSlice
export default reducer
