import { useEffect, useState ,useRef} from 'react'
import { LogoMain, Video1, discoverImage , LogoD } from '../../../assets/images';
import { Icon } from '@iconify/react'
//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBContainer, MDBRow , MDBCol } from 'mdb-react-ui-kit'
import { motion } from 'framer-motion'
import ReactPlayer from "react-player";


//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedVideo } from '../../../redux/reducer/discoverReducer';

export default function AgentVideoCard({item , index}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [divSize , setDivSize] = useState(width > 1200 ? "big_desktop" : width > 990 ? "desktop" : width > 767 ?  "tab" : "mobile")

 const divHeight = {
  big_desktop : "375.19px" , 
  desktop : "311px" , 
  tab : "284px" , 
  mobile : "327.19px" , 
 }

 const divWidth = {
  big_desktop : "202.33px" , 
  desktop : "202.33px" , 
  tab : "202.33px" , 
  mobile : "177px" , 
 }

 const imageHeight = {
  big_desktop : "375.19px" , 
  desktop : "311px" , 
  tab : "284px" , 
  mobile : "236px" , 
 }

 const imageWidth = {
  big_desktop : "202.33px" , 
  desktop : "202.33px" , 
  tab : "202.33px" , 
  mobile : "177px" , 
 }

  useEffect(() => {
    if(width > 1200) {
      setDivSize("big_desktop")
    } else if(width > 990) {
      setDivSize("desktop")
    }
    else if(width > 767) {
      setDivSize("tab")
    } else  {
      setDivSize("mobile")
    }
  },[width])

  return (
  <motion.div className='agent-video-card' style={{maxHeight : divHeight[divSize], width : divWidth[divSize] , maxWidth : divWidth[divSize] , padding : "0"}}>
    <motion.div className='cover-div' style={{width : imageWidth[divSize] , maxHeight : imageHeight[divSize] }} layoutId={"card-"+item.id}>
      <motion.div className='hover-div' layoutId={"div-"+item.id} onClick={() => 
       dispatch(setSelectedVideo(item))
      }></motion.div>
   { item?.medias?.[1]?.url ?  <img className='cover-image' src={item?.medias?.[1]?.url ? item?.medias?.[1]?.url : LogoMain} alt='d-concetp' style={{height : "100%" , width : "100%" , objectFit : "cover" , minHeight : "150px"}}/> : 
    <ReactPlayer
    className={`video-cover ${divSize}`}
     style={{maxHeight : width > 991 ? "284px" : "284px" , maxWidth : "202.33px" ,height : "100%" , width : "100%"  , objectFit : "cover"}}
     url={item.medias?.[0]?.url}
     width={"100%"}
     height={"100%"}
     loop={false}
     />
   }
    </motion.div>
    <div style={{padding : "0.5em"}}>
      <MDBRow>
        <label className='_label --text'>{item.description}</label>
      </MDBRow>
      <MDBRow>
        <MDBCol className='col-2'><div className='agent-avatar' style={{height : "20px" , width : "20px"}}>
          <img src={item?.upload_by_img?.[0]?.url ? item?.upload_by_img?.[0]?.url : LogoD} alt='d-concetp' style={{height : "100%" , width : "100%" , objectFit : "cover"}}/>
          </div></MDBCol>
        <MDBCol><label className='_label --name'>{item.upload_by}</label></MDBCol>
      </MDBRow>
    </div>
  </motion.div>
  )
}
