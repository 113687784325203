//lib
import { MDBCardImage, MDBRow } from 'mdb-react-ui-kit'

export default function AgentCard({ data }) {
  return (
    <>
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <section className="agent-card-container" key={index}>
            <div className="agent-card">
              <div className="agent-card-content">
                <div className="agent-card-title-div">
                  <span className="agent-card-title">Verified</span>
                </div>
                <div className="agent-card-body">
                  <span className="agent-card-subtitle --name">
                    {item.full_name_as_id ? item.full_name_as_id : 'ANONYMOUS'}
                  </span>
                  <span className="agent-card-subtitle --ic">{item.email ? item.email : ''}</span>
                  <br />
                  {/* <span className="agent-card-subtitle --ic">
                    {item.mobile_number ? `+6${item.mobile_number}` : ''}
                  </span> */}
                  <span className="agent-card-subtitle --ic">
                    {item.mobile_number_second ? (
                      <>
                        +6{item.mobile_number} <br />
                        +6{item.mobile_number_second}
                      </>
                    ) : (
                      `+6${item.mobile_number}`
                    )}
                  </span>

                  {/* <div className="">
                                <Icon icon="ic:twotone-barcode" />
                            </div> */}
                </div>
                Wong
                <div className="agent-profile-pic" width="40">
                  <MDBCardImage
                    src={
                      item.profile_picture
                        ? item.profile_picture
                        : require('../../../assets/images/agent_pic.webp')
                    }
                    width="100%"
                    height="100%"
                    alt="ready-stock-car-img"
                  />
                </div>
              </div>
            </div>
          </section>
        ))
      ) : (
        <MDBRow className="carList-container-grid m-0">
          <MDBRow
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: '6em',
              marginLeft: '0',
              marginRight: '0',
              color: '#D1D5DB',
              fontFamily: 'cabin-regular',
            }}>
            <label
              style={{
                fontFamily: 'inter-regular',
                fontSize: '22px',
                fontWeight: '600',
                color: '#33363b',
                fontStyle: 'italic',
                textTransform: 'capitalize',
              }}>
              No data available
            </label>
            {/* <Lottie animationData={DiscoveryNoData} style={animationStyle} /> */}
          </MDBRow>
        </MDBRow>
      )}
    </>
  )
}
